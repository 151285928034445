import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import {
  BgImage,
  BlogList,
  Container,
  Layout,
  PaginationLinks,
  Section,
} from "../components"
import SEO from "../components/seo"
import { useSpring, animated, config } from "react-spring"

const BlogPage = props => {
  const { data } = props
  const posts = data.allMdx.edges
  // Update postsPerPage in gatsby-node.js and graphql query for this page
  const postsPerPage = 3
  let numberOfPages

  const fadeUp = useSpring({
    opacity: 1,
    transform: "translateY(0%) ",
    from: { opacity: 0, transform: "translateY(-5%) " },
    delay: "500",
    config: config.wobbly,
  })

  numberOfPages = Math.ceil(data.allMdx.totalCount / postsPerPage)

  return (
    <Layout>
      <SEO title="Blog" />
      <Section>
        <Container>
          {/* <animated.div style={fadeUp}> */}
          <h2
            css={`
              margin-bottom: 0;
            `}
          >
            Latest Thoughts
          </h2>
          <div
            css={`
              min-height: 600px;
              margin: 0 -2rem;
            `}
          >
            <BlogList posts={posts} />
          </div>
          <PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
          {/* </animated.div> */}
        </Container>
      </Section>
      <BgImage
        fluid={data.desktop.childImageSharp.fluid}
        height="100vh"
        mobileHeight="50vh"
        title="desktop"
        overlayColor="rgba(0, 0, 0, 0.3)"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "beach.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 3) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 300)
          frontmatter {
            title
            path
            date
            author
            length
          }
        }
      }
    }
  }
`

export default BlogPage
